import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import maine from '../../../assets/images/albums/installations/environmental-justice/maine/Maine - textiles and mixed media 3x4ft - 2023.jpg';
import maineDetail1 from '../../../assets/images/albums/installations/environmental-justice/maine/Maine detail 1.jpg';
import maineDetail2 from '../../../assets/images/albums/installations/environmental-justice/maine/Maine detail 2.jpg';

import pollinators1 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/1- Iris versicolor.jpg';
import pollinators2 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/2- Rudbeckia lacinata.jpg';
import pollinators3 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/3- Bombus spp.jpg';
import pollinators4 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/4- Lobelia cardinalis.jpg';
import pollinators5 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/5- Lilium superbum.jpg';
import pollinators6 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/6- Echinacea purpurea.jpg';

import pollinators from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/Pollinators Menu.jpg';
import pollinatorsDetail1 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/Pollinator_s Menu detail 1.jpg';
import pollinatorsDetail2 from '../../../assets/images/albums/installations/environmental-justice/pollinators_menu/Pollinator_s Menu detail 2.jpg';

import installationView from '../../../assets/images/albums/installations/environmental-justice/I am the Observer and Nature is My Teacher - installation - web.jpg';
import magicPond from '../../../assets/images/albums/installations/environmental-justice/Magic Pond - web.jpg';
import irisVersicolor from '../../../assets/images/albums/installations/environmental-justice/Iris versicolor (Northern blue flag).jpg';
import amelanchierCanadensis from '../../../assets/images/albums/installations/environmental-justice/Amelanchier canadensis (Serviceberry).jpg';
import echinaceaPurpurea from '../../../assets/images/albums/installations/environmental-justice/Echinacea purpurea (Purple coneflower).jpg';
import rudbeckiaHirta from '../../../assets/images/albums/installations/environmental-justice/Rudbeckia hirta (Black-eye Susan).jpg';
import sabatiaKennedyana from '../../../assets/images/albums/installations/environmental-justice/Sabatia kennedyana (Plymouth rose gentian).jpg';
import liliumSuperbum from '../../../assets/images/albums/installations/environmental-justice/Lilium superbum (Turk\'s cap Lily).jpg';

import meadowsImage from '../../../assets/images/albums/installations/environmental-justice/meadows.jpg';
import meadowsDetail1Image from '../../../assets/images/albums/installations/environmental-justice/meadows-detail1.jpg';
import meadowsDetail2Image from '../../../assets/images/albums/installations/environmental-justice/meadows-detail2.jpg';
import blanketFlowerImage from '../../../assets/images/albums/installations/environmental-justice/blanket_flower_16x20.jpg';
import purpleconeflowerImage from '../../../assets/images/albums/installations/environmental-justice/purple_cone_flower_16x20.jpg';
import scarletBeebalmImage from '../../../assets/images/albums/installations/environmental-justice/scarlet_beebalm_16x20.jpg';
import serviceberryImage from '../../../assets/images/albums/installations/environmental-justice/serviceberry_16x20.jpg';
import wildBergamotImage from '../../../assets/images/albums/installations/environmental-justice/wild_bergamot_16x20.jpg';
import wildGeraniumImage from '../../../assets/images/albums/installations/environmental-justice/wild_geranium_20x19.jpg';

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <p>
        "Maine"
      </p>
      <p>
        Medium: 95% repurposed, yarn, textile, beads, genuine stones, handmade ceramic, polymesh bags and vintage bijouterie - 3 x 4.5 ft.
      </p>
      <p>
        Private Collection - 2023
      </p>
      <p>
        Inspired by the topography of Maine, surrounded by lush greenery of forests, lakes and the changing colors of the seasons. The idea of creating this tapestry was to collect repurposed textiles from close friends and family and surprise the collector with the story behind the work for her new home in Maine.
      </p>
      <p>
        In this practice I explore an art form that generated the need for material during the pandemic and awareness about the pollution of fast fashion and recycling.
The work becomes much more organic, the colors are carefully chosen with a theme in mind and it is only for private commission or specific installation the hospitality and the corporate sector that is committed to support environmental topics.
      </p>
      <GalleryWithLightbox
        photos={PHOTO_SET_MAINE}
        direction='row'
        margin={8}
      />
      <br />
      <br />
      <p>
        "Pollinator's Menu"
      </p>
      <p>
        Medium: acrylic, gold paint, recycled chocolate foil and repurpose materials on brown shopping bags - Installed individually on clipboards with cut silhouette butterflies. 17”H x 11”W each - 2023
      </p>
      <p>
        Climate change has impacted biodiversity around the globe, it is another environmental crisis that needs to be addressed along with the current changes of the climate, because everything is connected.
      </p>
      <p>
        This site-specific installation took part in the “State of The Earth” project at Midway Gallery in Fort Point, Boston. I selected a few of my favorite plants that are native to the American Northeast. The photographs were taken by myself in public and private gardens as a reference and each work has a brief text about the characteristics of the plants, and some important pollinators. It has been an inspiration to create these artworks as a venue to raise awareness of the biodiversity crisis.
      </p>
      <p>
        Climate action is crucial in the art sector, it is an environmental responsibility. I am excited to be able to contribute and continue to spread the word about the importance of planting native plants for pollinators no matter where you live, this is a global issue. Together we can help the natural world thrive.
      </p>
      <GalleryWithLightbox
        photos={PHOTO_SET_POLLINATORS_MENU_1}
        direction='row'
        margin={8}
      />
      <GalleryWithLightbox
        photos={PHOTO_SET_POLLINATORS_MENU_2}
        direction='row'
        margin={8}
      />
      <br />
      <br />
      <p>
        "I am the Observer and Nature is My Teacher"<br />
      </p>
      <p>
        This site-specific installation was created for SPOKE Art Gallery in Boston, with repurposed materials such as glass, chocolate paper, genuine stones, found objects and handmade ceramics from previous projects. The flowers are painted in acrylic on paper, cut and mounted on birch wood panels and recycled cardboard. These new works feature images of plants and pollinators native to North America.<br /><br />
        Curated by Kathleen Bitetti - 2022<br />
      </p>
      <GalleryWithLightbox
        photos={PHOTO_SET_1}
        direction='row'
        margin={8}
      />
      <br /><br />
      <p>
        "Meadows - The Gardening Revolution"<br />
        Repurposed textiles, vintage metal flowers, handmade ceramic, semi precious stones, yarn and wire - 34"H x 68"W - 2021
      </p>
      <p>
        The Danforth Art Museum Juried Exhibition 2022<br />
        Framingham Massachusetts.
      </p>
      <p>
        During the spring of 2021 I was inspired by an organization that makes meadows for biodiversity. I joined the opportunity to research and learn about the importance of planting native plants and trees for pollinators, so they can continue the natural process of the ecosystem.
      </p>
      <p>
        There is an urgency to this practice and I want to be part of a better future by challenging city parks, private gardens and the next generations to practice sustainable gardening.
      </p>
      <p>
        Exotic plants sold in nurseries do not support pollinator diversity. Habitat loss, climate change, toxic pesticides and disease are major drivers of the decline of living organisms; bees, butterflies, other insects and birds are endangered, declining in numbers and have an unpredictable future.
      </p>
      <p>
        Green grounds of corporate buildings, university campuses and churches, for example, can be transformed within meadows.
Mega green yards, as neat as they may look, do nothing to help the ecosystem, as most are maintained with pesticides.
      </p>
      <GalleryWithLightbox
        photos={PHOTO_SET_2}
        direction='column'
        margin={8}
      />
      <br /><br />
      <p>
        Native flowers of Northeast of America: "Purple coneflower" - "Scarlet bee balm" - "Blanket flower" - "Serviceberry" - "Wild Bergamot" - "Wild geranium"<br />
        The native flower paintings are in acrylic on paper and are available for limited edition printing of 250 - size 16” x 20” on acid-free German paper upon request.<br />
        Also available for open print - size 8" x 10" on acid-free paper.<br /><br />
        <a href="https://www.saatchiart.com/liliana.folta" target="_blank" rel="noreferrer noopener">https://www.saatchiart.com/liliana.folta</a>
      </p>
      <GalleryWithLightbox
        photos={PHOTO_SET_3}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
);

const PHOTO_SET_MAINE = [
  {
    src: maine,
    width: 1280,
    height: 1933,
    caption: 'Maine - repurposed, yarn, textile, beads, genuine stones, handmade ceramic, polymesh bags and vintage bijouterie - 3 x 4.5 ft. - Private Collection'
  },
  {
    src: maineDetail1,
    width: 1280,
    height: 1839,
    caption: 'Maine detail 1'
  },
  {
    src: maineDetail2,
    width: 1280,
    height: 1965,
    caption: 'Maine detail 2'
  },
];

const PHOTO_SET_POLLINATORS_MENU_1 = [
  {
    src: pollinators1,
    width: 1280,
    height: 1857,
    caption: 'Iris versicolor - acrylic, gold paint and recycled chocolate foil on brown paper bag - 17”H x 11”W'
  },
  {
    src: pollinators2,
    width: 1280,
    height: 1820,
    caption: 'Rudbeckia laciniata - acrylic, gold paint, recycled chocolate foil and found objects on brown paper bag - 17”H x 11”W'
  },
  {
    src: pollinators3,
    width: 1280,
    height: 1823,
    caption: 'Bombus spp - acrylic, gold paint, recycled chocolate foil and found objects on brown paper bag - 17”H x 11”W'
  },
  {
    src: pollinators4,
    width: 1280,
    height: 1857,
    caption: 'Lobelia cardinalis - acrylic, gold paint, recycled chocolate foil and found objects on brown paper bag - 17”H x 11”W'
  },
  {
    src: pollinators5,
    width: 1280,
    height: 1872,
    caption: 'Lilium superbum - acrylic, gold paint and recycled chocolate foil on brown paper bag - 17”H x 11”W'
  },
  {
    src: pollinators6,
    width: 1280,
    height: 1847,
    caption: 'Echinacea purpurea - acrylic, gold paint and recycled chocolate foil on brown paper bag - 17”H x 11”W'
  },
];

const PHOTO_SET_POLLINATORS_MENU_2 = [
  {
    src: pollinators,
    width: 960,
    height: 902,
    caption: 'Pollinator’s Menu - Works on paper installed on clipboards - 56”H x 85”W'
  },
  {
    src: pollinatorsDetail1,
    width: 960,
    height: 1210,
    caption: 'Pollinator’s Menu Detail 1'
  },
  {
    src: pollinatorsDetail2,
    width: 960,
    height: 811,
    caption: 'Pollinator’s Menu Detail 2'
  },
];

const PHOTO_SET_1 = [
  {
    src: installationView,
    width: 1280,
    height: 853,
    caption: 'Mixed media - 6 round birch wood panels 16" Dim. 1 recycled cardboard panel 36"W x 53"H'
  },
  {
    src: magicPond,
    width: 1262,
    height: 1280,
    caption: 'Acrylic and mixed media mounted on recycled cardboard panel - 36" x 53"'
  },
  {
    src: irisVersicolor,
    width: 1200,
    height: 1012,
    caption: 'Acrylic on paper and mixed media mounted on birch wood panel - 16" Dim.'
  },
  {
    src: amelanchierCanadensis,
    width: 1280,
    height: 1079,
    caption: 'Acrylic, flower print on German paper and other mixed media mounted on birch wood panel - 16" Dim.'
  },
  {
    src: echinaceaPurpurea,
    width: 1267,
    height: 1280,
    caption: 'Acrylic on paper and mixed media mounted on birch wood panel - 16" Dim.'
  },
  {
    src: rudbeckiaHirta,
    width: 1200,
    height: 1012,
    caption: 'Acrylic on paper and mixed media mounted on birch wood panel - 16" Dim.'
  },
  {
    src: sabatiaKennedyana,
    width: 1200,
    height: 1012,
    caption: 'Acrylic on paper and mixed media mounted on birch wood panel - 16" Dim.'
  },{
    src: liliumSuperbum,
    width: 1200,
    height: 1012,
    caption: 'Acrylic on paper and mixed media mounted on birch wood panel - 16" Dim.'
  },
]

const PHOTO_SET_2 = [
  {
    src: meadowsImage,
    width: 960,
    height: 636,
    caption: 'Meadows - The Gardening Revolution - Repurposed textiles, vintage metal flowers, handmade ceramic, semi precious stones, yarn and wire - 34"H x 68"W - 2021'
  },
  {
    src: meadowsDetail1Image,
    width: 960,
    height: 636,
    caption: 'Meadows - The Gardening Revolution - Detail 1'
  },
  {
    src: meadowsDetail2Image,
    width: 960,
    height: 635,
    caption: 'Meadows - The Gardening Revolution - Detail 2'
  }
];

const PHOTO_SET_3 = [
  {
    src: blanketFlowerImage,
    width: 960,
    height: 758,
    caption: 'Blanket flower - acrylic and mixed media on paper - 16" x 20"'
  },
  {
    src: purpleconeflowerImage,
    width: 960,
    height: 758,
    caption: 'Purple coneflower - acrylic and mixed media on paper - 16" x 20"'
  },
  {
    src: scarletBeebalmImage,
    width: 960,
    height: 758,
    caption: 'Scarlet beebalm - acrylic and mixed media on paper - 16" x 20"'
  },
  {
    src: serviceberryImage,
    width: 960,
    height: 758,
    caption: 'Serviceberry - acrylic and mixed media on paper - 16" x 20"'
  },
  {
    src: wildBergamotImage,
    width: 960,
    height: 758,
    caption: 'Wild bergamot - acrylic and mixed media on paper - 16" x 20"'
  },
  {
    src: wildGeraniumImage,
    width: 960,
    height: 758,
    caption: 'Wild geranium - acrylic and mixed media on paper - 16" x 20"'
  }
];
